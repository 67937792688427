import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/';

class DevicesService {
    getDevicesBoard() {
        return axios.get(API_URL + 'devices', {headers: authHeader()});
    }

    getDevice(deviceUniqueId) {
        return axios.get(API_URL + `devices/${deviceUniqueId}`, {headers: authHeader()});
    }

    create(data) {
        return axios.post(API_URL + 'devices', data, {headers: authHeader()});
    }

    attachUser(deviceUniqueId, data) {
        return axios.put(API_URL + `devices/${deviceUniqueId}/attach-user`, data, {headers: authHeader()});
    }

    getProductsBoard(deviceUniqueId) {
        return axios.get(API_URL + `devices/${deviceUniqueId}/products`, {headers: authHeader()});
    }

    getTanksBoard(deviceUniqueId) {
        return axios.get(API_URL + `devices/${deviceUniqueId}/tanks`, {headers: authHeader()});
    }
}

export default new DevicesService();
