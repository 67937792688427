<template>
    <div class="container">
        <div class="tabs-example">
            <div class="example example-1">
                <h2>Device name: <strong>{{ dName }}</strong></h2>
                <tabs class="Tab-exp1">
                    <tab title="Products">
                        <products></products>
                    </tab>
                    <tab title="Tanks">
                        <tanks></tanks>
                    </tab>
                    <tab title="Orders">
                        <orders></orders>
                    </tab>
                    <tab title="Device management">
                        <h3>Device management</h3>
                    </tab>
                </tabs>
            </div>
        </div>
    </div>
</template>

<script>
import Tabs from "@/components/Lib/Tabs.vue";
import Tab from "@/components/Lib/Tab.vue";
import Products from "@/components/Products.vue";
import Tanks from "@/components/Tanks.vue";
import Orders from "@/components/Orders.vue";
import DevicesService from "@/services/devices.service";

export default {
    name: "Device",
    components: {Tab, Tabs, Products, Tanks, Orders},
    data() {
        return {
            dName: null,
            device: {
                id: null,
                unique_id: "",
                name: "",
                user_device: {
                    user: {
                        name: "",
                    },
                },
                device_version_device: {
                    device_version: {
                        name: "",
                    }
                },
                user: {
                    email: "",
                },
                user_password: "",
                user_id: "",
                verified: "",
                type: "",
                created_at: "",
            },
        };
    },
    methods: {
        getDevice() {
            DevicesService.getDevice(this.$route.params.uniqueId).then(
                (response) => {
                    this.device = response.data[0];
                },
                (error) => {
                    this.devices =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
    mounted() {
        if (undefined === this.$route.params.deviceName) {
            this.getDevice();
        }
    },

    watch: {
        device: {
            deep: true,
            handler(newValue) {
                if (newValue) {
                    this.dName = this.device.name
                }
            },
        },
    }
};
</script>
