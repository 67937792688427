<template>
    <header class="jumbotron">
        <strong>Tanks:</strong>
        <table class="table table-hover" v-if="tanks">
            <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Pump number</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="tank in tanks.data" :key="tank">
                <td>
                    <div v-if="!editing">
                        {{tank.name}}
                    </div>
                    <div v-else>
                        <input v-model="tank.name">
                    </div>
                </td>
                <td>
                    {{tank.pump_number}}
                </td>
            </tr>
            </tbody>
            <button v-if="!editing" v-on:click.prevent="editing = true">Edit</button>
            <button v-else v-on:click.prevent="updateTanks()">Save</button>
        </table>
    </header>
</template>

<script>
import DevicesService from "../services/devices.service";
import TanksService from "@/services/tanks.service";
import {defineComponent} from "vue";

export default defineComponent({
    name: "Tanks",
    props: {
        deviceId: null
    },

    data() {
        return {
            tanks: null,
            tank: {
                name: null,
                pump_number: null,
                unique_id: null
            },
            editing: null,
            itemsProcessed: 0
        }
    },

    mounted() {
        this.getTanksBoard(this.$route.params.uniqueId)
    },

    methods: {
        getTanksBoard(deviceUniqueId) {
            DevicesService.getTanksBoard(deviceUniqueId).then(
                (response) => {
                    this.tanks = response.data;
                },
                (error) => {
                    this.tanks =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        updateTanks() {
            this.tanks.data.forEach(function(tank){
                this.updateTank(tank);
            }, this)
        },

        updateTank(tankModel) {
            TanksService.update(tankModel.unique_id, tankModel)
                .then(response => {
                    console.log(response.data);
                    this.message = 'Tank was updated successfully!';

                    this.itemsProcessed++;
                    if(this.itemsProcessed === this.tanks.data.length) {
                        this.editing = false;
                        this.itemsProcessed = 0;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },
    }
})
</script>

<style>

</style>
