<script lang="ts">
import {
    defineComponent,
    onMounted,
    onBeforeUnmount,
    ref,
    watch,
    toRefs,
    h,
    computed,
    onBeforeUpdate
} from "vue"

export default defineComponent({
    name: "Tabs",
    props: {
        defaultIndex: {
            default: 0,
            type: Number
        },
        resetTabs: {
            type: Boolean,
            default: false
        },
        direction: {
            type: String,
            default: "horizontal",
            validator(value) {
                return ["horizontal", "vertical"].includes(value)
            }
        },
        position: {
            type: String,
            default: "left",
            validator(value) {
                return ["left", "start", "end", "center"].includes(value)
            }
        },
        reverse: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: {
        tabChanged(index) {
            return index !== undefined || index !== null
        }
    },
    setup(props, { emit, slots }) {
        const { defaultIndex, resetTabs, position, direction, reverse } = toRefs(
            props
        )

        const selectedIndex = ref(0)
        const tabs = ref([])
        const _tabItems = ref([])

        const onTabKeyDown = e => {
            if (e.ctrlKey || e.metaKey) {
                if (parseInt(e.key) - 1 in tabs.value) {
                    e.preventDefault()
                    switchTab(e, parseInt(e.key) - 1, tabs.value[parseInt(e.key) - 1])
                }
            }
        }

        const reset = () => {
            selectedIndex.value = 0
        }

        const switchTab = (_, index, isDisabled) => {
            if (!isDisabled) {
                selectedIndex.value = index
                emit("tabChanged", index)
            }
        }

        onMounted(() => {
            getTabItems()
            document.addEventListener("keydown", onTabKeyDown)
        })

        onBeforeUnmount(() => {
            document.removeEventListener("keydown", onTabKeyDown)
        })

        watch(defaultIndex, (newValue) => {
            if (newValue !== selectedIndex.value) {
                selectedIndex.value = newValue
            }
        })

        watch(resetTabs, (newValue) => {
            if (newValue === true) reset()
        })

        onBeforeUpdate(() => {
            getTabItems()
        })

        const getTabItems = () => {
            _tabItems.value.splice(0, _tabItems.value.length)
            slots.default().forEach(component => {
                if (component.type.name && component.type.name === "Tab") {
                    _tabItems.value.push(component)
                } else {
                    component.children.forEach(cComp => {
                        if (cComp.type.name && cComp.type.name === "Tab") {
                            _tabItems.value.push(cComp)
                        }
                    })
                }
            })
        }

        const getTitleSlotContent = titleSlot => {
            let slotContent = null
            let shouldSkip = false
            slots.default().forEach(item => {
                if (shouldSkip) {
                    return
                }

                if (item.type === "template" && item.props.name === titleSlot) {
                    slotContent = item.children
                    shouldSkip = true
                } else {
                    if (item.children.length) {
                        item.children.forEach(cItem => {
                            if (shouldSkip) {
                                return
                            }
                            if (cItem.props.name === titleSlot) {
                                slotContent = cItem.children
                                shouldSkip = true
                            }
                        })
                    }
                }
            })
            return slotContent === null ? [] : slotContent
        }

        const tabToDisplay = computed(() => {
            return _tabItems.value.map((item, idx) => {
                return h(
                    "div",
                    {
                        class: "tab",
                        style: `display: ${selectedIndex.value == idx ? "block" : "none"}`
                    },
                    item
                )
            })
            // return h("div", { class: "tab" }, _tabItems.value[selectedIndex.value]);
        })

        return () => {
            const tabList = []
            _tabItems.value.forEach((tab, index) => {
                const _tabProps = tab.props

                const titleContent = _tabProps["title-slot"]
                    ? getTitleSlotContent(_tabProps["title-slot"])
                    : _tabProps.title
                const isDisabled =
                    _tabProps.disabled === true || _tabProps.disabled === ""
                tabs.value[index] = isDisabled

                tabList.push(
                    h(
                        "li",
                        {
                            class: "tab-list__item",
                            tabIndex: "0",
                            role: "tabItem",
                            "aria-selected": selectedIndex.value === index ? "true" : "false",
                            "aria-disabled": isDisabled ? "true" : "false",
                            onClick: e => {
                                switchTab(e, index, isDisabled)
                            }
                        },
                        titleContent
                    )
                )
            })

            return h(
                "div",
                {
                    class: `tabs ${direction.value} ${reverse.value ? "reverse" : ""}`,
                    role: "tabs"
                },
                [
                    h(
                        "ul",
                        { class: `tab-list ${position.value}`, role: "tabList" },
                        tabList
                    ),
                    ...tabToDisplay.value
                ]
            )
        }
    }
})
</script>

<style lang="scss">
:root {
    --primary-color: #4313aa;
    --border-color: #e2e2e2;
    --disabled-text-color: #999;
}
.tabs {
    display: grid;
    grid-template-columns: 1fr;
    .tab-list {
        list-style: none;
        display: flex;
        padding-left: 0;
        border-bottom: 1px solid var(--border-color);
        &.center {
            justify-content: center;
        }
        &.end {
            justify-content: flex-end;
        }
        &__item {
            padding: 8px 10px;
            cursor: pointer;
            user-select: none;
            transition: border 0.3s ease-in-out;
            position: relative;
            bottom: -1px;
            text-transform: uppercase;
            font-size: 0.85rem;
            letter-spacing: 0.05rem;

            &:not(:first-child) {
                margin-left: 10px;
            }

            &[aria-selected="true"] {
                border-bottom: 2px solid var(--primary-color);
                font-weight: 700;
                color: var(--primary-color);
            }
            &[aria-disabled="true"] {
                cursor: not-allowed;
                color: var(--disabled-text-color);
            }
        }
    }
    &.horizontal {
        &.reverse {
            .tab-list {
                grid-row: 2;
                border: none;
                border-top: 1px solid var(--border-color);
            }
        }
    }

    &.vertical {
        grid-template-columns: auto 1fr;
        gap: 1rem;
        .tab-list {
            flex-direction: column;
            border-bottom: none;
            border-right: 1px solid var(--border-color);

            &__item {
                margin-left: 0;
                border-radius: 0;

                &[aria-selected="true"] {
                    border: none;
                    border-left: 2px solid var(--primary-color);
                }
            }
        }

        &.reverse {
            grid-template-columns: 1fr auto;
            .tab-list {
                grid-column: 2;
                border: none;
                border-left: 1px solid var(--border-color);
            }

            .tab {
                grid-row: 1;
                grid-column: 1;
            }
        }
    }
}
.tabs-example {
    display: grid;
    place-items: center;
    text-align: left;

    .example {
        width: 80%;
        padding: 0 1rem;
        border-radius: 8px;
        background: #fdfdff;
        border: 2px solid #e7e7f5;
        margin-block-end: 1rem;
    }
}
</style>
