<template>
    <header class="jumbotron">
        <strong>Orders:</strong>
        <p>
            Status: 1 - pending payment, 2 - paid
        </p>
        <table class="table table-hover" v-if="orders">
            <thead>
            <tr>
                <th scope="col">Unique id</th>
                <th scope="col">Description</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
            </tr>
            </thead>
            <tbody v-for="order in orders.data" :key="order">
                <tr :class="{ 'bg-warning': order.status === 1, 'bg-success': order.status === 2 }">
                    <td>
                        {{order.unique_id}}
                    </td>
                    <td>
                        {{order.description}}
                    </td>
                    <td>
                        {{order.amount}} UAH
                    </td>
                    <td>
                        {{order.status}}
                    </td>
                </tr>
            </tbody>
        </table>
    </header>
</template>

<script>
import {defineComponent} from "vue";
import OrdersService from "@/services/orders.service";
import Pusher from "pusher-js";

export default defineComponent({
    name: "Orders",
    props: {
        deviceId: null
    },

    data() {
        return {
            orders: null,
            order: {
                id: null,
                unique_id: "",
                description: "",
                amount: "",
                device_id: "",
                status: "",
                created_at: "",
                updated_at: "",
            },
            submitted: false,
            init: true
        }
    },

    mounted() {
        this.getOrdersBoard(this.$route.params.uniqueId)
        this.setUpPusher();
    },

    methods: {

        getOrdersBoard(deviceUniqueId) {
            OrdersService.getOrdersBoard(deviceUniqueId).then(
                (response) => {
                    this.orders = response.data;
                },
                (error) => {
                    this.orders =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        setUpPusher() {
            let pusher = new Pusher('c7d8c71932aef858bcc6', {
                cluster: 'eu'
            });

            let channel = pusher.subscribe('payments-channel');
            channel.bind('payment-accepted', function (data) {
                console.log(data);
                this.getOrdersBoard(this.$route.params.uniqueId);
            }, this);
        }
    }
})
</script>

<style>
    .content {
        background: rgba(0, 0, 0, .3);
    }
</style>
