import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/';

class DevicesService {
    update(tankUniqueId, tankObject) {
        return axios.put(API_URL + `tanks/${tankUniqueId}`, tankObject, {headers: authHeader()});
    }
}

export default new DevicesService();
