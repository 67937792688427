import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/';

class ProductsService {
    create(data) {
        return axios.post(API_URL + 'products', data, {headers: authHeader()});
    }

    delete(productUniqueId) {
        return axios.delete(API_URL + `products/${productUniqueId}`, {headers: authHeader()});
    }

    fillInWithTanks(productUniqueId, product) {
        return axios.put(API_URL + `products/${productUniqueId}/fill-in-with-tanks`, product, {headers: authHeader()});
    }
}

export default new ProductsService();
