<template>
    <header class="jumbotron">
        <div class="submit-form">
            <div v-if="submitted">
                <h4>Created {{product.name}}!</h4>
            </div>

            <div v-if="!init">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        required
                        v-model="product.name"
                        name="name"
                    />
                </div>

                <button @click="saveProduct" class="btn btn-success">Submit</button>
            </div>

            <div v-else>
                <button class="btn btn-success" @click="newProduct">Add product</button>
            </div>
        </div>
        <strong>Products:</strong>
        <table class="table table-hover" v-if="products">
            <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Visible</th>
                <th scope="col">Price</th>
                <th scope="col">Created</th>
                <th scope="col">Manage</th>
            </tr>
            </thead>
            <tbody v-for="product in products.data" :key="product">
                <tr>
                    <td>
                        {{product.name}}
                    </td>
                    <td>
                        {{product.visible}}
                    </td>
                    <td>
                        {{product.price}} UAH
                    </td>
                    <td>
                        {{product.created_at}}
                    </td>
                    <td>
                        <button v-on:click.prevent="deleteProduct(product.unique_id)" type="button" class="btn btn-danger">Delete</button>
                    </td>
                </tr>
                <div class="content">
                    <form @submit.prevent="onUpdateContent(product)">
                        <tr>
                            Content:
                        </tr>
                        <tr v-for="(products_content_entry, index) in product.products_content" :key="index">
                            <td>
                                {{products_content_entry.device_tank.name}}
                            </td>
                            <td>
                                <input min="0" required="true" type="number" v-model="product.products_content[index].volume_ml">
                            </td>
                        </tr>
                        <tr>
                            <button type="submit" class="btn btn-success btn-sm button-medium">
                                Save
                            </button>
                        </tr>
                    </form>
                </div>
            </tbody>
        </table>
    </header>
</template>

<script>
import ProductsService from "../services/products.service";
import DevicesService from "../services/devices.service";
import {defineComponent} from "vue";

export default defineComponent({
    name: "Products",
    props: {
        deviceId: null
    },

    data() {
        return {
            products: null,
            product: {
                id: null,
                unique_id: "",
                name: "",
                device_id: "",
                picture: "",
                products_content: {
                    volume: null,
                    device_tank: {
                        name: null,
                    }
                },
                visible: "",
                created_at: "",
                price: "",
            },
            submitted: false,
            init: true
        }
    },

    mounted() {
        this.getProductsBoard(this.$route.params.uniqueId)
    },

    methods: {
        saveProduct() {
            const data = {
                name: this.product.name,
                device_id: this.$route.params.uniqueId,
            };

            ProductsService.create(data)
                .then(response => {
                    this.product.id = response.data.id;
                    this.submitted = true;
                    this.init = true;
                    this.getProductsBoard(this.$route.params.uniqueId);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        newProduct() {
            this.submitted = false;
            this.init = false;
            this.product = {};
        },

        deleteProduct(productUniqueId) {
            if (!confirm('Sure?')) {
                return;
            }
            ProductsService.delete(productUniqueId)
                .then(() => {
                    this.getProductsBoard(this.$route.params.uniqueId);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        getProductsBoard(deviceUniqueId) {
            DevicesService.getProductsBoard(deviceUniqueId).then(
                (response) => {
                    this.products = response.data;
                },
                (error) => {
                    this.products =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        onUpdateContent(product) {
            ProductsService.fillInWithTanks(product.unique_id, product).then(
                () => {
                    this.getProductsBoard(this.$route.params.uniqueId);
                },
                (error) => {
                    this.products =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    }
})
</script>

<style>
    .content {
        background: rgba(0, 0, 0, .3);
    }
</style>
