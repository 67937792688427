import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/';

class OrdersService {
    getOrdersBoard(deviceUniqueId) {
        return axios.get(API_URL + `device-orders/${deviceUniqueId}`, {headers: authHeader()});
    }
}

export default new OrdersService();
